import React, {Fragment, useState, useEffect, useRef} from 'react';
import { Container, Card, Row, Col, Button, Input, Nav, NavItem, NavLink,  TabContent, TabPane } from "reactstrap";
import { useHistory } from 'react-router-dom';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import Moment from 'react-moment';
import moment from 'moment';
import { colors, OGExpirationDays, emailTypeOptions } from "../../../../../config/constants";
import { isMobile } from 'react-device-detect';
import useTemplate from '../useTemplate';
import '../Template.scss';
import TemplateHeaderSettings from './TemplateHeaderSettings';
import useInput from "../../../../../SharedComponents/SharedEffects/useInput";
import validate from 'validate.js';
import templateApi from '../../../../../api/templateApi';
import orderGuideApi from '../../../../../api/orderGuideApi';
import usersApi from '../../../../../api/usersApi';
import { useSelector, useDispatch } from "react-redux";
import AlertModal from '../../../../../SharedComponents/Modals/AlertModal/AlertModal';
import QuestionModal from '../../../../../SharedComponents/Modals/QuestionModal/QuestionModal';
import { showModal } from "../../../../../reducers/modals/modalsActions";
import MatchHeadersSelect from './MatchHeadersSelect';
import TestRow from './TestRow';
import TestInvalidRow from './TestInvalidRow';
import classnames from 'classnames';
import RejectModal from './RejectModal';
import EmailPreviewModal from './EmailPreviewModal';


const TemplateSettings = (props) => {
    
    const history = useHistory();
    const dispatch = useDispatch();

    const section = history.location.pathname.split('/');
    const fileId = parseInt(section[section.length-2]);
    const { data } = useTemplate.useDetailApi(fileId);
    const [tempData, setTempData] = useState(data);
    const [step, setStep] = useState(1);


    // Step 1
    const headerFromRow = useInput.useText(data.header_from_row, {isNumber: {min: 0, allowEmpty: false}});
    const dataFromRow = useInput.useText(data.data_from_row, {isNumber: {min: 0, allowEmpty: false}});

    // Step 2
    const [fileHeaders, setFileHeaders] = useState([]);
    const [ogOptions, setOgOptions] = useState([]);
    const [headerMap, setHeaderMap] = useState('');
    const matchHeaders = useRef([]);

    // Step 3
    const name = useInput.useText(data.name);
    const expire = useInput.useText(!!data.days_to_expire ? data.days_to_expire : OGExpirationDays, {isNumber: {min: OGExpirationDays, allowEmpty: false}});

    //Step 4
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }
    const [testResult, setTestResult] = useState([]);
    const [validated, setValidated] = useState('');
    const [splited, setSplited] = useState('');
    const [blocked, setBlocked] = useState('');
    const [nonBlocked, setNonBlocked] = useState('');

    //Step 6
    
    const [assignedMerchants, setAssignedMerchants] = useState({});
    const [lastUpload, setLastUpload] = useState({});
    const [emailText, setEmailText] = useState('');
    
    
    // Step 1 Functions
    const validateCheck = () => {
        const fields = {
            headerRow: parseInt(headerFromRow.value),
            dataRow: parseInt(dataFromRow.value)
          };
    
        const constraints = {
            headerRow: {presence: {allowEmpty: false}, numericality: {onlyInteger: true, greaterThanOrEqualTo: 0}},
            dataRow: {presence: {allowEmpty: false}, numericality: {onlyInteger: true, greaterThan: parseInt(headerFromRow.value)}}
        };
        
        const result = validate(fields, constraints);
        return  !!!result;
    };

    const clickNext = () => {
          
        if(validateCheck()){
            const params = {
                header_from_row: headerFromRow.value,
                data_from_row: dataFromRow.value
            }
            
            templateApi.saveInitialSettings(data.id, params).then((res) => {
                const file = res.data.data;
                setTempData(file);
                setStep(2);
                getFileHeaders(file.id, {header_from_row: file.header_from_row});
          
              })
              .catch(error => {
                const newProps = {
                  title: 'Settings Failed',
                  message: 'There was an error while saving initial settings',
                  btnLabel: 'Ok',
                  btnSize: 'lg',
                  color: 'green'
                };
                dispatch(showModal(AlertModal, newProps));
              });
        }        
    
    };

    const cancel = () => {
        history.push(`/templates/`);
    };

    useEffect(() => {
        headerFromRow.set(data.header_from_row);
        dataFromRow.set(data.data_from_row);
    }, [data]);

    
    // Step 2 functions
    const getFileHeaders = (id, params) => {
        templateApi.getFileHeaders(id, params).then((res) => {
          const columns = res.data.data.columns;
          getOGTemplateHeaders(id, columns);
        })
        .catch(error => {      
        });
    };
    
    const getOGTemplateHeaders = (id, columns) => {
        templateApi.getOgTemplateHeaders(id).then((res) => {
            const ogTempFields = res.data.data;
            matchHeaders.current = ogTempFields.length>0 ? ogTempFields : columns.map((col, i) => { return { label: col, match: [""]} });
            getOGHeaders(id, columns);
        })
        .catch(error => {      
        });
    };

    const getOGHeaders = (id, columns) => {
        templateApi.getOgHeaders(id).then((res) => {
            const ogFields = res.data.data;
            let options = ogFields.map((col, i) => { return { value: col.toUpperCase(), label: col} });
            options.unshift({value:'', label:'None'});
            setOgOptions(options);
            setFileHeaders(columns);      
        })
        .catch(error => {      
        });
    };

    const matchToAll = (ev, index) => { 
        const nEv = ev.length>0 ? ev.map((e) => { return e.value}) : [];
        const newMatch = matchHeaders.current.map((m, i) => {
                            if (i !== index) return m;
                            return { ...m, match: nEv };
                        });
        matchHeaders.current = newMatch;
    };  

    const clickMatch = () => {
        const params = {
            parsed_fields: fileHeaders,
            og_fields: matchHeaders.current
        }
    
        templateApi.matchHeaders(data.id, params).then((res) => {
            const file = res.data.data;
            setTempData(file);
            setStep(3);
            name.set(file.name);
            expire.set(file.days_to_expire);
          })
          .catch(error => {
            const newProps = {
              title: 'Settings Failed',
              message: 'There was an error while matching headers',
              btnLabel: 'Ok',
              btnSize: 'lg',
              color: 'green'
            };
            dispatch(showModal(AlertModal, newProps));
          });
        
    };

    useEffect(() => {
        setHeaderMap(fileHeaders.map((col, i) =>
            <Row key={`headerCol_${i}`} className='py-1'>
                <Col xs={5} className='d-flex justify-content-end text-main'>{col}</Col>
                <Col xs={5} className=''>
                    <MatchHeadersSelect id={`sel_${i}`} placeholder='Select' options={ogOptions} set={(ev) => matchToAll(ev, i)} init={matchHeaders.current.filter((m,j) => (j===i))[0]}/>
                </Col>
            </Row>
        ));
    }, [fileHeaders]);
    

    // Step 3 functions
    const validateCheckFinal = () => {
        const fields = {
            nameField: name.value,
            expireField: parseInt(expire.value)
          };
    
        const constraints = {
            nameField: {presence: {allowEmpty: false}},
            expireField: {presence: {allowEmpty: false}, numericality: {onlyInteger: true}}
        };
        
        const result = validate(fields, constraints);
        return  !!!result;
    };

    const clickSave = () => {
        if(validateCheckFinal()){
            const params = {
                name: name.value,
                days_to_expire: expire.value
            }
            
            templateApi.saveInitialSettings(data.id, params).then((res) => {
                const file = res.data.data;
                setTempData(file);
                setStep(4);          
                
              })
              .catch(error => {
                const newProps = {
                  title: 'Error',
                  message: 'There was an error while saving template',
                  btnLabel: 'Ok',
                  btnSize: 'lg',
                  color: 'green'
                };
                dispatch(showModal(AlertModal, newProps));
              });
        }
    };


    // Step 4 function
    const clickTest = () => {
        templateApi.test(data.id).then((res) => {
            const test = res.data.data;
            setValidated(test.validatedItems.map((t,i) => <TestRow  key={`test_${i}`} data={t}/> ));
            setSplited(test.splitedItems.length===0 ?  <Card className='p-2'>No Items found</Card>: test.splitedItems.map((t,i) => <TestRow  key={`test_${i}`} data={t}/> ));
            setBlocked(test.blockedItems.length===0 ? <Card className='p-2'>No Items found</Card>:  test.blockedItems.map((t,i) => <TestInvalidRow  key={`test_${i}`} data={t}/> ));
            setNonBlocked(test.nonBlockedItems.length===0 ? <Card className='p-2'>No Items found</Card> : test.nonBlockedItems.map((t,i) => <TestInvalidRow  key={`test_${i}`} data={t}/> ));
            setTestResult(test);
            setStep(5);
        })
        .catch(error => {
            const newProps = {
                title: 'Error',
                message: 'There was an error while testing template',
                btnLabel: 'Ok',
                btnSize: 'lg',
                color: 'green'
            };
            dispatch(showModal(AlertModal, newProps));
        });
    };


    // Step 5 function
    const clickApprove = () => {
        if(validated.length==0){
            const invalidProps = {
                title: '',
                message: 'We detect no valid items in this file',
                btnLabel: 'Ok',
                btnSize: 'lg',
                color: 'green'
            };
            dispatch(showModal(AlertModal, invalidProps));
            return;
        }
        templateApi.approve(data.id).then((res) => {
            if(!data.queue.merchants){ return; }

            if(data.queue.status){
                let message=<Row className='my-3'><Col className='text-center'>Order guide will be uploaded for</Col></Row>;
                let messageMerch='';            
                messageMerch = data.queue.merchants.map((m, i) => (<Row key={`qm-${m.id}-${i}`} className='p-1'><Col className='text-center'>{m.nickname}</Col></Row>));
                const newProps = {
                    message: <Fragment>{message } {messageMerch}</Fragment>,
                    btnLabel: 'Ok',
                    btnSize: 'lg',
                    color: 'green',
                    onOk: () => processQueue()
                };
                dispatch(showModal(AlertModal, newProps));
            }else{
                checkTemplateMatches();
            }
        })
        .catch(error => {
            const errorProps = {
                title: 'Error',
                message: 'There was an error while approving template',
                btnLabel: 'Ok',
                btnSize: 'lg',
                color: 'green'
            };
            dispatch(showModal(AlertModal, errorProps));
        });
      };

    const processQueue = () => {
        if(!data.queue){ return; }

        const params = {
            queue: '['+data.queue.id+']',
            templateId: data.id
        };
        
        orderGuideApi.uploadQueue(params).then((res) => {
            checkTemplateMatches();
        })
        .catch(error => {
            const newProps = {
                title: 'Error',
                message: 'There was an error while processing queue',
                btnLabel: 'Ok',
                btnSize: 'lg',
                color: 'green'
            };
            dispatch(showModal(AlertModal, newProps));
        });
    };


    const checkTemplateMatches = () => {

        templateApi.checkTemplateMatches(data.id).then((res) => {            
            const matches = res.data.data;
            history.push('/templates');

            if(matches.length===0){                
                return;
            }
            const questionProps = {
                message: 'We detected similar order guides fitting this template, would you like to test them?',
                menu: isMobile ? true : false,
                leftButton: {
                    title: 'Skip',
                    style: 'btn-outlined',                    
                },
                rightButton: {
                    title: 'Proceed',
                    color: 'green',
                    onClick: () => history.push(`/templates/${data.id}/matches`)
                }
            };
            dispatch(showModal(QuestionModal, questionProps));
        })
        .catch(error => {
            const newProps = {
                title: 'Error',
                message: 'There was an error while checking template matches in queue',
                btnLabel: 'Ok',
                btnSize: 'lg',
                color: 'green'
            };
            dispatch(showModal(AlertModal, newProps));
        });
    };

    const clickPreReject = () => {
        let assignedMerchantsVar = {};        
        usersApi.assignedMerchants(data.queue.upload_user.id)
        .then((res) => {
            setAssignedMerchants(res.data.data);
            assignedMerchantsVar = res.data.data;
            return orderGuideApi.lastUpload(data.queue.upload_user.id,data.queue.distributor.id);
        }).then((res) => {
            if(res.data.data.data){
                const lastUploadVar = res.data.data.data[0];
                setLastUpload(lastUploadVar);
                
                const rejectProps = {
                    title: 'Rejection Info',
                    user: data.queue.upload_user,
                    uploadForMerchants: data.queue.merchants,
                    distributor: data.queue.distributor,
                    assignedMerchants: assignedMerchantsVar,
                    lastUpload: lastUploadVar,
                    btnLabel: 'Preview Rejection',
                    color: 'blue',
                    onOk: clickEmailPreview
                };
                dispatch(showModal(RejectModal, rejectProps));
            }
        })
        .catch(error => {
            
        });
    };

    const clickEmailPreview = (user, uploadForMerchants, distributor, assignedMerchants, lastUpload, emailType) => {        
        let emailNewText = "";
        const dsrFullName = (user.first_name ? user.first_name : '') + ' ' + (user.last_name ? user.last_name : '');
        let merchantsString = "";
        uploadForMerchants.map((m, i) =>
            merchantsString += m.nickname + (i < uploadForMerchants.length-1 ? ', ' : '')
        );

        const merchs = uploadForMerchants.map((m,i) => { return m.id});
        usersApi.getLastLogged(merchs)
        .then((res) => {
            const lastLoggedCustomers = res.data.data;
            if(emailType == emailTypeOptions.type1){
                emailNewText = "Dear "+dsrFullName+", \n\nWe received your request to approve your order guide template for "+merchantsString+" and are unable to do so. Please refer to the attached sample template for more information on required information and formatting and resubmit your order guide. Feel free to use the exact template we have provided. \n\nThank you, \nFoodBAM Team";
            }else{
                if(lastUpload){
                    const lastOgDate = moment(lastUpload.create_time).format('MM/DD');
                    let lastUploadMerchantsString = "";
                    lastUpload.detail.map((m, i) =>
                        lastUploadMerchantsString += m.merchant.nickname + (i < lastUpload.detail.length-1 ? ', ' : '')
                    );
                    emailNewText = "Dear "+dsrFullName+",\n\nWe received your request to approve a new order guide template for "+merchantsString+" however, we already have an approved template from you on file. \n\nPlease refer to the previously approved order guide template you uploaded on "+ lastOgDate +" for "+lastUploadMerchantsString+" and adjust your file accordingly. If you need to change how your order guides will be submitted going forward, please contact us at: distportal@getorderly.com \n\nThank you, \nFoodBAM Team";
                }else{
                    emailNewText = "Dear "+dsrFullName+",\n\nWe received your request to approve a new order guide template for "+merchantsString+" however, we already have an approved template from you on file. \n\nIf you need to change how your order guides will be submitted going forward, please contact us at: distportal@getorderly.com \n\nThank you, \nFoodBAM Team";
                }
                
                                
            }

            const emailProps = {
                title: 'Send RejectionEmail',
                user: user,
                distributor: distributor,
                assignedMerchants: assignedMerchants,
                lastUpload: lastUpload,
                emailText: emailNewText,
                emailType: emailType,
                lastLoggedCustomers: lastLoggedCustomers,
                leftButton: {
                    title: 'Cancel',
                    color: 'orange'
                },
                leftClick: clickPreReject,
                rightButton: {
                    title: 'Send',
                    color: 'green'
                },
                rightClick: clickReject,
            };
            dispatch(showModal(EmailPreviewModal, emailProps));
        })
        .catch(error => {
            
        });

    };

    const clickReject = (to, body, emailType, file) => {
        
        templateApi.rejectTemplate(data.id, to, body, emailType, file)
        .then((res) => {
            // console.log({res})
            history.push(`/templates`);
        })
        .catch(error => {
            
        });
    };
    

    return (
        <Fragment>
            <TemplateHeaderSettings step={step}/>

            <ShowIf show={step===1}>
                <Card className='py-4'>
                    <Row className='py-2'>
                        <Col className='d-flex justify-content-end'>
                            Header from Row
                        </Col>
                        <Col xs={2}>
                            <Input type='number' pattern="[0-9]*" value={headerFromRow.value} onChange={headerFromRow.set} placeholder='0'/>
                        </Col>
                        <Col xs={4}></Col>
                    </Row>
                    <Row className='py-2'>
                        <Col className='d-flex justify-content-end'>
                            Data from Row
                        </Col>
                        <Col xs={2}>
                            <Input type='number' pattern="[0-9]*" value={dataFromRow.value} onChange={dataFromRow.set} placeholder='0'/>
                        </Col>
                        <Col xs={4}></Col>
                    </Row>
                    <Row className='py-2'>
                        <Col className='d-flex justify-content-end'>
                            <Button color='orange' className={isMobile ? 'btn-block' : ''} onClick={cancel}>Cancel</Button>
                        </Col>
                        <Col className='d-flex justify-content-start'>
                            <Button color='green' className={isMobile ? 'btn-block' : ''} onClick={clickNext}>Next</Button>
                        </Col>
                    </Row>
                </Card>
            </ShowIf>
            
            <ShowIf show={step===2}>
                <Card className='py-4'>
                    {headerMap}
                    <Row className='py-2'>
                        <Col className='d-flex justify-content-end'>
                            <Button color='orange' className={isMobile ? 'btn-block' : ''} onClick={cancel}>Cancel</Button>
                        </Col>
                        <Col className='d-flex justify-content-start'>
                            <Button color='green' className={isMobile ? 'btn-block' : ''} onClick={clickMatch}>Next</Button>
                        </Col>
                    </Row>
                </Card>
            </ShowIf>

            <ShowIf show={step===3}>
                <Card className='py-4'>
                    <Row className='py-2'>
                        <Col className='d-flex justify-content-end'>
                            Name
                        </Col>
                        <Col xs={4}>
                            <Input type='text' id='template_name' value={name.value} onChange={name.set} placeholder='Template Name'/>
                        </Col>
                        <Col xs={2}></Col>
                    </Row>
                    <Row className='py-2'>
                        <Col className='d-flex justify-content-end'>
                            Expire after(days)
                        </Col>
                        <Col xs={4}>
                            <Input type='number' pattern="[0-9]*" value={expire.value} onChange={expire.set} placeholder='0'/>
                        </Col>
                        <Col xs={2}></Col>
                    </Row>
                    <Row className='py-2'>
                        <Col className='d-flex justify-content-end'>
                            <Button color='orange' className={isMobile ? 'btn-block' : ''} onClick={cancel}>Cancel</Button>
                        </Col>
                        <Col className='d-flex justify-content-start'>
                            <Button color='green' className={isMobile ? 'btn-block' : ''} onClick={clickSave}>Review</Button>
                        </Col>
                    </Row>
                </Card>
            </ShowIf>

            <ShowIf show={step===4}>
                <Card className='py-4'>
                    <Row className='py-2'>
                        <Col className='d-flex justify-content-end text-gray'>
                            Name
                        </Col>
                        <Col xs={4}>
                            {name.value}
                        </Col>
                        <Col xs={2}></Col>
                    </Row>
                    <Row className='py-2'>
                        <Col className='d-flex justify-content-end text-gray'>
                            File
                        </Col>
                        <Col xs={4}>
                            {tempData.template_file}
                        </Col>
                        <Col xs={2}></Col>
                    </Row>
                    <Row className='py-2'>
                        <Col className='d-flex justify-content-end text-gray'>
                            Header from Row
                        </Col>
                        <Col xs={4}>
                            {tempData.header_from_row}
                        </Col>
                        <Col xs={2}></Col>
                    </Row>
                    <Row className='py-2'>
                        <Col className='d-flex justify-content-end text-gray'>
                            Expire after(days)
                        </Col>
                        <Col xs={4}>
                            {expire.value}
                        </Col>
                        <Col xs={2}></Col>
                    </Row>
                    <Row className='py-2'>
                        <Col className='d-flex justify-content-end text-gray'>
                            Status
                        </Col>
                        <Col xs={4} className='text-capitalize'>
                            {tempData.status}
                        </Col>
                        <Col xs={2}></Col>
                    </Row>
                    <Row className='py-2'>
                        <Col className='d-flex justify-content-end'>
                            <Button color='orange' className={isMobile ? 'btn-block' : ''} onClick={cancel}>Cancel</Button>
                        </Col>
                        <Col className='d-flex justify-content-start'>
                            <Button color='green' className={isMobile ? 'btn-block' : ''} onClick={clickTest}>Test</Button>
                        </Col>
                    </Row>
                </Card>
            </ShowIf>
        
            <ShowIf show={step===5}>
                <div className='p-4'>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={'clickable' + (activeTab === '1' ? ' active' : ' inactive')}  onClick={() => { toggle('1'); }} >
                                Blocked Items ({!!testResult.blockedItems ?  testResult.blockedItems.length : 0})
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={'clickable' + (activeTab === '2' ? ' active' : ' inactive')}  onClick={() => { toggle('2'); }} >
                                Non Blocked Items ({!!testResult.nonBlockedItems ? testResult.nonBlockedItems.length : 0})
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={'clickable' + (activeTab === '3' ? ' active' : ' inactive')}  onClick={() => { toggle('3'); }} >
                                Splited Items ({!!testResult.splitedItems ? testResult.splitedItems.length : 0})
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={'clickable' + (activeTab === '4' ? ' active' : ' inactive')}  onClick={() => { toggle('4'); }} >
                                Validated Items ({!!testResult.validatedItems ? testResult.validatedItems.length : 0 })
                            </NavLink>
                        </NavItem>
                    </Nav>
                    
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Row className='py-2 text-gray'>
                                <Col xs={1}> DIN </Col>
                                <Col xs={1}> Type</Col>
                                <Col xs={3}> Description</Col>
                                <Col xs={1}> Value</Col>
                                <Col xs={1}> Row</Col>
                            </Row>
                            {blocked}
                        </TabPane>
                    </TabContent>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="2">
                            <Row className='py-2 text-gray'>
                                <Col xs={1}> DIN </Col>
                                <Col xs={1}> Type</Col>
                                <Col xs={4}> Description</Col>
                                <Col xs={2}> Value</Col>
                                <Col xs={1}> Row</Col>
                            </Row>
                            {nonBlocked}
                        </TabPane>
                    </TabContent>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="3">
                            <Row className='py-2 text-gray'>
                                <Col xs={1}> DIN </Col>
                                <Col xs={2}> Name</Col>
                                <Col xs={1}> UOM</Col>
                                <Col xs={1}> Price</Col>
                                <Col xs={1}> Pack Size</Col>
                                <Col xs={1}>Outer Pack</Col>
                                <Col xs={1}>Inner Pack</Col>
                                <Col xs={1}>Pack Uom</Col>
                                <Col xs={1}> Unit Price</Col>
                                <Col xs={1}> Brand</Col>
                                <Col xs={1}> Category</Col>
                            </Row>
                            {splited}
                        </TabPane>
                    </TabContent>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="4">
                            <Row className='py-2 text-gray'>
                                <Col xs={1}> DIN </Col>
                                <Col xs={2}> Name</Col>
                                <Col xs={1}> UOM</Col>
                                <Col xs={1}> Price</Col>
                                <Col xs={1}> Pack Size</Col>
                                <Col xs={1}>Outer Pack</Col>
                                <Col xs={1}>Inner Pack</Col>
                                <Col xs={1}>Pack Uom</Col>
                                <Col xs={1}> Unit Price</Col>
                                <Col xs={1}> Brand</Col>
                                <Col xs={1}> Category</Col>
                            </Row>
                            {validated}
                        </TabPane>
                    </TabContent>

                    <Row className='py-2'>
                        <Col className='d-flex'></Col>
                        <Col className='d-flex justify-content-center'>
                            <Button color='orange' className={isMobile ? 'btn-block' : ''} onClick={cancel}>Cancel</Button>&nbsp;&nbsp;
                            <Button color='green' className={isMobile ? 'btn-block' : ''} onClick={clickApprove}>Approve</Button>&nbsp;&nbsp;
                            <Button color='secondary' className={isMobile ? 'btn-block' : ''} onClick={clickPreReject}>Reject</Button>
                        </Col>
                        <Col className='d-flex'></Col>
                    </Row>
                </div>
            </ShowIf>
                  
        </Fragment>
       
    )

}

export default TemplateSettings;